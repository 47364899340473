.users-container, .brokers-container {
    // tabs
    .nav-tabs {
        margin: 0;
        gap: 1rem;

        .nav-item {
            padding: 0 !important;
            border-top: none !important;
            border-radius: 5px;

            &:hover {
                transform: none !important;
            }

            a {
                padding: 3px;
                font-size: 12px;
            }
        }
    }

    .leadUser {
        margin: 10px 0;
    }

    .associate-user, .associate-broker {
        padding: 1rem;
        .form-add  {
            .form-group {
                width: 100%;
                margin: 0;

                input {
                    width: 96%;
                }

                @media (max-width: 700px) {
                    margin-bottom: 1rem;
                }
            }

            button {
                margin-top: 1.7rem;
                display: inline-flex;
                align-items: center;
                justify-content: center;
                gap: 5px;
                min-width: 2.5rem;
                width: 8%;
                padding: 5px 8px;

                svg {
                    width: 16px;
                }

                @media (max-width: 400px) {
                    min-width: 100%;
                }
            }

            .label-btn-add {
                display: none;
            }
            .input-add {
                text-transform: initial;
                margin-right: 1rem;
                
                @media (max-width: 700px) {
                    margin-right: 0 !important;
                    width: 100% !important;
                }
            }

            @media (max-width: 700px) {
                flex-wrap: wrap;
                width: 100%;

                .input-add {
                    width: 100%;
                }

                .label-btn-add {
                    display: block;
                }
                .btn-add {
                    width: auto !important;
                    margin: 0;
                    padding: 1px 6px !important;
                }
            }
        }
    }
}
.dark-layout {
    .input-add-user > div {
        background-color: transparent;
        border: solid 1px #80808034 !important;
    }
    input.input-add-user {
        border: solid 1px #a7a7a742 !important;
    }
    svg.css-tj5bde-Svg {
        color: #a7a7a765;
    }
    span.css-1u9des2-indicatorSeparator {
        background-color: #a7a7a742;
    }
    #react-select-50-listbox {
        background-color: #283046 !important;
    }
}
