.tags-container {
    // add tags
    .nav-tabs {
        margin: 0;
        gap: 1rem;

        .nav-item {
            padding: 0 !important;
            border-top: none !important;
            border-radius: 5px;

            &:hover {
                transform: none !important;
            }

            a {
                padding: 3px;
                font-size: 12px;
            }
        }
    }
    .tab-addTag {
        padding: 1.3rem 1rem 1rem;

        .select-tag-container {
            display: flex;
            flex-direction: column;
            width: 100%;

            .titulo-campo-tag {
                font-size: 11px;
                opacity: .8;
                margin-bottom: 2px;
            }

            .select-tag {
                display: inline-flex;
                gap: 1rem;

                .input-select-tags {
                    width: 93%;
                }
                button {
                    display: inline-flex;
                    align-items: center;
                    justify-content: center;
                    gap: 5px;
                    min-width: 2.5rem;
                    width: 7%;
                    padding: 5px;
                    svg {
                        width: 16px;
                    }
                }
            }
        }

        .divider {
            margin: 10px 0 0;
            width: 100%;
            text-transform: lowercase !important;
            color: #2b2b2bbe;
        }

        .create-tag {
            width: 100%;

            .titulo-campo-tag {
                font-size: 11px;
                opacity: .8;
                margin-bottom: 2px;
            }

            form  {
                gap: 1rem;

                input {
                    width: 93%;
                }

                button {
                    display: inline-flex;
                    align-items: center;
                    justify-content: center;
                    gap: 5px;
                    min-width: 2.5rem;
                    width: 7%;
                    padding: 5px;
                    svg {
                        width: 16px;
                    }
                }
            }
        }
    }

    // tags - pill badges
    .badge-tag {
        margin: 1rem .8rem .5rem;
        cursor: grab;
        padding: 5px 10px;
        border-radius: 5px !important;
        position: relative;

        #delete-badge {
            position: absolute;
            top: -4px;
            right: -7px;
            color: #494949;
            background-color: rgba(255, 255, 255, 0.781);
            border-radius: 50%;
            border: solid 1px #53535354;
            width: 16px;
            height: 15px;
            cursor: pointer;
            transition: .2s !important;
            &:hover {
                background-color: rgb(231, 77, 77);
                color: white;
            }
        }
    }
}
