.error-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 2rem;

    .not-found-img {
        max-width: 300px;
    }

    @media (max-width: 767px) {
        .title-error {
            font-size: 20px;
        }
        .not-found-img {
            width: 200px;
        }
    }
}

