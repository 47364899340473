.tela-forgotPassword {
    background: url('../../../images/imagesbravo/banners/Bravo_03_V3.png') center;
    background-repeat: no-repeat;
    background-size: cover;

    .resetPassword-container {
        background-color:#ffffff;
    }

    .card-forgot-title {
        text-align: center;
    }

    @media (max-width: 991.9px) {
        .resetPassword-container {
            background-color: transparent;

            .resetPassword-area {
                background-color: #ffffff;
                padding: 2rem;
                border-radius: 10px;
            }
        }
    }


}





