.scroll-addLead {
    width: 100%;
}

.header-addLead {
    border: solid #6b6b6b1b 1px;

    .card-header {
        justify-content: start;
        padding: 1.5rem;
        align-items: center;

        h4 {
            margin-bottom: 0;
            cursor: default;
            font-size: 17px;
        }
    }
}

.form-container-addLead {
    padding: 1rem;
    border: solid #6b6b6b1b 1px;
}

.form-control-selectDate {
    border: solid  1px #0000003d !important;
    width: 100%;
    padding: 8px;
    border-radius: 5px;
}

.currency-input {
    border: solid  1px #0000003d !important;
    width: 100%;
    padding: 8px;
    border-radius: 5px;
}

.insert-leadAddress {
    display: flex;
    width: 100%;
    gap: 8px;
}

.insert-adressState, .insert-adressCity {
    width: 100% !important;
    div {
        margin-bottom: 0;
    }
}

.dark-layout {
    .form-control-selectDate {
        background-color: transparent;
        border-color: rgba(255, 255, 255, 0.082) !important;
        color: rgb(207, 207, 207);
    }
    .currency-input {
        background-color: transparent;
        border-color: rgba(255, 255, 255, 0.082) !important;
        color: rgb(207, 207, 207);
    }
}
