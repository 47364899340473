.new-register-card {
    .register-container {
        margin-top: 1rem;
        .register-collapse {
            .field-name {
                font-weight: 500;
                border: solid 1px #24242422;
                border-radius: 6px;
                padding: 6px 8px;
                margin-bottom: .6rem;    
                cursor: pointer;
                &:hover {
                    background-color: #68686819;
                    svg {
                        fill: #4080c1;
                        stroke: #4080c1;
                    }
                }
            }
            .no-register-found {
                border-left: solid 2px #687d9335;
                margin: -1rem auto 1.3rem 6px;
            }
            .scrollbar-container {
                padding: 4px 8px !important;
                margin-bottom: 1rem;
            }
            .MuiDataGrid-toolbarContainer, .MuiDataGrid-selectedRowCount {
                display: none;
            }
            .MuiDataGrid-columnHeaders {
                background-color: #bbbbbb3f;
                div {
                    font-weight: bold;
                    font-size: 14px;
                    svg, span {
                        display: none;
                    }
                    
                }
            }
            .MuiDataGrid-withBorderColor {
                border: none !important;
            }
            .MuiDataGrid-row {
                border-bottom: solid 1px #bbbbbb63;
                .MuiDataGrid-cell:first-child {
                    border-right: solid 1px #bbbbbb63 !important;
                }
            }
            .MuiDataGrid-footerContainer {
                justify-content: flex-start;
            }
            .MuiTablePagination-displayedRows {
                margin-bottom: 0;
            }
            .Mui-selected {
                background-color: transparent !important;
            }
            .MuiDataGrid-cell {
                text-overflow: ellipsis;
            }
            .MuiDataGrid-cell:hover {
                color: inherit !important;
            }
            .MuiDataGrid-cell:focus, .MuiDataGrid-columnHeader:focus {
                outline: none !important;
            }
            .MuiDataGrid-row .MuiDataGrid-cell:first-child {
                justify-content: center;
            }
        }
    }
    .input-search-cadastros {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: -3px;    
        input {
            max-width: 250px;
            max-height: 2rem;
            line-height: 2rem;
            padding: 10px;
        }
        .btn {
            border-radius: 50%;
            width: 30px;
            height: 30px;
            padding: 0;
            max-height: 26px;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }
}

.modal-register {
    .form-register {
        button {
            margin-left: .2rem;
            padding: 9px 1.5rem;
            margin-bottom: 5px;
        }
        small {
            color: red;
        }
        .special-label {
            display: none !important;
        }
        #phone {
            font-size: 14px;
        }     
        @media (max-width: 500px) {
            .address-group {
                flex-wrap: wrap;
                .form-group {
                    margin: 0 !important;
                    &:last-child {
                        margin-top: 3px !important;
                    }
                }
            }
        }       
    }
}

.dark-layout {
    .MuiDataGrid-root, .MuiToolbar-root {
        color: #bababa; 
    }
}