//fixando paginação
.todo-app-list {
    position: relative;

    nav {
        position: absolute;
        bottom: 0;
    }

    .scrollbar-container {
        padding-bottom: 2.5rem;
    }
}

// qtd de leads sendo exibidos
.render-count {
    width: auto;
    display: flex;
    align-items: center;
    gap: 4px;
    font-size: 12px;
    font-weight: 500;
    position: absolute;
    left: 1rem;
    top: 8px;
    transition: .2s;

    .btn-hideCount {
        margin-left: 8px;
        cursor: pointer;
        display: none;

        &:hover {
            color: rgb(141, 58, 58);
        }
    }

    @media (max-width: 865px) {
        span {
            display: none;
        }
    }
    @media (max-width: 715px) {
        background: #e3e3e3f3;
        color: #464e5c;
        top: -2rem;
        left: 0;
        padding: 5px 10px 5px 5px;
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
        .btn-hideCount {
            display: block;
        }
    }
}
@media (max-width: 715px) {
    .hidden-count {
        left: -5.4rem;
    }
}

//estilizando paginação
.pagination-container {
    margin: 0 !important;
    width: 100% !important;


     ul {
        width: 100% !important;
        margin: 0;
        border-radius: 0;
        gap: 2%;
        background: #f3f2f7;
        box-shadow: 3px 0px 7px 0px rgba(0, 0, 0, 0.189);
        padding: 1px 0;

        li {
            a {
                border-radius: 50% !important;
                font-weight: 500;
                color: #343b47 !important;

                &:hover {
                    background: #9da8b964 !important;
                }
            }
        }
        //quando tiver selecionado
        .active {
            background: transparent !important;
            a {
                background: $blue-primary !important;
                transform: scale(1.11);
                position: relative;
                bottom: 3.1px;
                &:hover {
                    background: $blue-primary !important;
                }
            }
        }
    }
}

.dark-layout {
    .render-count {
        background: none;
    }
    .pagination-container {
      ul {
          background: #2d354d;
          box-shadow: 3px 0px 7px 0px rgba(184, 184, 184, 0.189);

          li {
              a {
                  color: #aeaeae !important;
                  background: #242b3d !important;
                  &:hover {
                      background-color: #bfbebe37 !important;
                  }
              }
          }
          //quando tiver selecionado
          .active {
              a {
                  background-color: #4080c1 !important;
              }
          }
      }
    }
}


