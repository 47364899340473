.commission-receipts-container {
   .receipt-table-container {
        height: 80vh; /* Defina uma altura específica ou máxima */
        overflow: auto; /* Ativa a rolagem vertical se o conteúdo for maior que a altura definida */
        .p-datatable {
            height: 100%;
            .p-datatable-wrapper {
                height: 100%;
                max-height: 80% !important;
            }
        }
   }
    
    .receipt-filters {
        width: 100%;
        @media (min-width: 767px) {
            width: 50%;
        }
    }
    .applied-filters {
        margin-left: -3px;
        span {
            font-weight: 500;
        }
        b {
            color: $blue-primary;
        }
    }    
}
