.addLead-container {
    margin: 1.3rem 1.5rem;

    div {
        gap: 7px;
    }
    button {
        padding: 8px;
    }
}

// placeholder dos select em filtros sidebar
.css-1jqq78o-placeholder {
  font-weight: normal;
  color: #80808094 !important;
}
// listagem options select em filtros sidebar
.css-1nmdiq5-menu {
  color: #797979;
  font-weight: normal;
}

.sidebar-menu-list {

  .list-filters-sidebar {
    padding-top: 10px;

    .list-group-item {
      padding: 6px 1.5rem !important;
    }
  }

  .filterBy {
      gap: 7px;
      padding-top: 0;
  }
  .title-status {
      gap: 7px;
      padding-top: 5px;
  }

  .icon-label-filter {
    max-width: 14px;
    color: #b0b0b0;
  }

  .btn-default-sidebar {
      padding: 2px 1.5rem;
      button {
          padding: 8px;
      }
      .dropdown-item {
        width: 100%;
      }
      .disabled {
        &:hover {
          cursor: default !important;
        }
      }
  }
  .btn-select-tag {
    margin: 8px 0 0;
  }

  .clearFilters {
      padding: 2px 1.5rem;
      button {
          padding: 8px;
      }
  }
}

.dark-layout {
  .sidebar {
    // container select em filtros sidebar
    .css-13cymwt-control {
      background: rgba(22, 29, 49, 0);
      border-color: #d8d6de21 !important;
    }
    // input value filtros sidebar
    div.css-1fdsijx-ValueContainer {
      input {
        color: #d1d1d1cc !important;
      }
    }
    // placeholder dos select em filtros sidebar
    .css-1jqq78o-placeholder {
      font-weight: normal;
      color: #a3a3a394 !important;
    }
    // listagem options select em filtros sidebar
    .css-1nmdiq5-menu {
      background: rgb(50, 59, 82);
      color: #d1d1d1cc;
    }
    // campo input select quando clicado
    .css-t3ipsp-control {
      background: rgb(50, 59, 82);
    }
    .css-1nmdiq5-menu div div {
      background: rgb(50, 59, 82);
     &:hover {
      background: rgba(80, 93, 125, 0.311);
      color: $blue-primary;
      cursor: pointer;
     }
    }
    // badge adicionada das options
    .css-1p3m7a8-multiValue {
      background: rgb(76, 85, 109);
      div {
        color: #d1d1d1cc !important;
      }
    }
    // botao para remover selecionado
    .css-12a83d4-MultiValueRemove:hover {
      svg {
        color: rgb(65, 65, 65);
      }
    }

    .edit-color-on-dark {
      color: #ffffff91 !important;
    }
  }
}