.modal-policy-registration {
    .not-saved {
        border: solid 2px #dd9b0d8c !important;
        border-radius: 7px;
    }
    .not-saved-input-number {
        input {
            border: solid 2px #dd9b0d8c !important;
        }
    }
    .invalid-input-number {
        input {
            border: solid 2px #dd0d0d8c !important;
        }
    }
    .alert-not-saved {
        display: flex;
        align-items: center;
        justify-content: space-between;
        button {
            padding: 4px 7px;
            margin-right: .75rem;
            font-size: 12px;
        }
        @media (max-width: 500px) {
            flex-direction: column;
            justify-content: center;
            button {
                margin: 4px 0;
            }
        }
    }
    .p-inputnumber {
        width: 100% !important;
        white-space: nowrap;
        input {
            width: 82%;
            height: 2.6rem;
        }
        button {
            padding: 8px;
        }
    }
    @media (max-width: 768px) {
        .form-group div {
            width: 100% !important;
        }
    }
}
