.content-body {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.contacts-container {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
}

.add-contact {
    display: flex;
    justify-content: space-between;
    margin-bottom: 1rem;
    .btn {
        padding: 4px 6px !important;
        svg {
            max-width: 13px;
            height: auto;
            margin-left: 1px;
        }
        @media (max-width: 400px) {
            span {
                display: none;
            }
        }
    }  
}

.icon-title-lead{
    font-size: 15px;
    margin-right: -2px;
}

.item-name {
    display: flex;
    justify-content: space-between;
    width: 100%;

    .list-group-item {
        padding: 8px !important;
        background: #b2aeae0e;
        border-radius: 5px;
        cursor: pointer;

        h2 {
            margin: 2px;
            padding-left: 3px;
            font-size: 14px;
        }
    }
}

.details {
    height: 100%;
}

.container-all-contacts {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.modal-add-contact {
    .form-group {
        textarea {
            resize: none;
            font-size: 13px;
            padding: 6px 10px;
            border: solid 1px #00000028;
            border-radius: 6px;
            &::placeholder {
                opacity: .6;
            }
            &:focus {
                outline: solid 1px #1e73c7;
            }
        }
        .phone-input {
            display: flex;
            gap: 8px;
            .btn {
                padding: 8px;
            }
        }
        .entered-numbers {
            span {
                margin: 5px 3px;
                opacity: .9;
                svg {
                    margin-left: 6px !important;
                    border-radius: 3px;
                    cursor: pointer;
                    &:hover {
                        background-color: #fff;
                        color: rgb(230, 69, 69);
                    }
                }
            }
        }
    }
}

.swingElementAnimation {
    animation: alert 375ms infinite;
}

@keyframes alert {
    0% {
        transform: rotate(0deg);
    }
    25% {
        transform: rotate(5deg);
    }
    50% {
        transform: rotate(0deg);
    }
    75% {
        transform: rotate(-5deg);
    }
    100% {
        transform: rotate(0deg);
    }
}

.lead-details {
    width: 100%;
    height: 100%;
    animation: detailsComingIn .2s;

    .card-body {
        margin: 1rem !important;
    }


    .header-leadContent {
        justify-content: start;
        padding-bottom: 12px;
        border-bottom: solid #6b6b6b18 2px;
        margin-bottom: 1rem;
        background-color: #ffff;
    }
    .contact-name h4 {
        @media (max-width: 650px) {
            font-size: 15px !important;
        }
    }
    .body-leadContent {
        margin: 1.5rem;
        background-color: #ffff;
        border: solid 2px #6b6b6b1b;
        border-radius: 5px;
        i {
            font-size: 14px;
            text-transform: capitalize;
        }
    }

    h2 {
        margin-top: 3px;
        font-size: 1.3em;
        font-weight: normal;
    }
}

.details-ComingIn {
    animation: detailsComingIn .2s;
}
.details-ComingOut {
    animation: detailsComingOut .2s;
}

@keyframes detailsComingIn {
    from {
        transform: translateX(5000px);
    } to {
        transform: translateX(0);
    }
}
@keyframes detailsComingOut {
    from {
        transform: translateX(0);
    } to {
        transform: translateX(5000px);
    }
}

.information {
    text-transform: uppercase;
    border-radius: 10px;

    .label-for-information {
        display: flex;
        align-items: center;
        gap: 5px;
    }
    #city-icon {
        color: rgb(177, 57, 57);
    }

    span {
        font-size: 12px;
        font-weight: bold;
    }

    h4 {
        margin-top: 3px;
        font-size: 16px;
        font-weight: normal;
    }
}

.contatos {
    transition: all .2s;
    padding: 4px 0;
    &:hover {
        transform: translateY(-3px);
        svg, .see-more {
            color: $blue-primary;
        }
    }

    .list-group-item {
        gap: 1rem;
        .contact-data {
            span {
                font-weight: 500 !important;
                text-transform: none;
            }
            svg {
            max-width: 14px;
            height: auto;
            margin-right: 4px;
            }
        }
        .see-more {
            transition: .2s;
            gap: 5px;
            font-size: 12px;
            padding: 4px;
            font-weight: 500;
        }
        @media (max-width: 550px)  {
            flex-direction: column;
            align-items: flex-start !important;
            .see-more {
                border: solid 1px #00000023;
                border-radius: 5px;
                padding: 3px 6px;
                font-size: 10px;
            }
        }
    }        
}

.contacts-icon {
    color: #427cd4;
}

.detail:hover {
    color: $blue-primary;
}

.dark-layout {
    .header-leadContent {
        background-color: transparent;
        border-bottom: solid 2px #ffffff1c;
    }
    .body-leadContent {
        border: solid 2px #4081c153;
        background-color: transparent;
    }
    .detail:hover h2 {
        color: $blue-primary;
    }
}