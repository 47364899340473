// Mixins
@mixin flexCenter {
    display: flex;
    align-items: center;
    justify-content: center;
}
@mixin flexLabel {
    display: flex;
    align-items: center;
    justify-content: left;
    gap: 5px;
}
