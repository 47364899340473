.date-filter-container:not(.date-filter-dashboard) {
    justify-content: center;
    flex-direction: column;
    .form-control {
        background-color: inherit;
    }
}
.date-filter-container {
    display: flex;
    span {
        font-size: 12px;
        margin-bottom: 2px;
    }
    .flatpickr-input {
        color: rgb(66, 66, 66);
        opacity: .9;
        border: solid 1px #adadada9 !important;
        &:hover {
            border-color: #868686a9 !important;
        }
    }
    .flatpickr-input::placeholder {
        color: #44444494;
    }
}
.flatpickr-disabled {
    color: #6b6b6b94 !important;
}
.flatpickr-calendar {
    border: solid 1px #a0a0a04b !important;
    .flatpickr-weekdays, .flatpickr-weekday {
        background-color: #ffff !important;
        color: #484848 !important;
    }
    .flatpickr-weekdays {
        border-bottom: solid 1px #a0a0a04b;
    }
    .flatpickr-months {
        .flatpickr-month {
            border-bottom: solid 1px #a0a0a04b;
            min-height: 38px;
            .flatpickr-current-month {
                padding: 7px !important;
            }
        }
        .flatpickr-prev-month, .flatpickr-next-month {
            padding: 7px 10px !important;
        }
    }
    @media (max-width: 370px) {
        min-width: 200px;
        width: 100% !important;
        .flatpickr-rContainer {
            width: 100%;
        }
        .flatpickr-days {
            width: auto ;
        }
        .flatpickr-monthDropdown-months{
            font-size: 16px !important;
        }
        .dayContainer {
            justify-content: center;
        }
        .cur-year {
            font-size: 16px !important;
            margin-top: 3px !important;
        }
        .flatpickr-weekdaycontainer {
            margin-left: 1em;
            .flatpickr-monthDropdown-months {
                max-width: 80px;
            }
        }
        .flatpickr-weekday {
            max-width: 40px !important;
        }
    }
}
.dark-layout {
    .flatpickr-days, .flatpickr-day, .flatpickr-weekdays, .flatpickr-weekday {
        background-color: #323b55 !important;
        color: #c2c2c2 !important;
    }
    .flatpickr-input::placeholder {
        color: #b4b4b4 !important;
    }
    .form-control {
        border: solid 1px #aaaaaa5b !important;
        background-color: transparent !important;
    }
    .date-filter-container.date-filter-dashboard {
        .form-control {
            background-color: #343d55 !important;
        }
    }
    .clear-date-value {
        svg {
            color: #5c6276;
        }
    }
}
