.process-endorsements {
    .card {
        border: solid 1px #91919123;
        border-left: solid 2px rgb(217, 149, 24);
        border-top-left-radius: 2px;
        border-bottom-left-radius: 2px;
        box-shadow: none;

        .card-name {
            margin-bottom: 2px;
            a:hover {
                text-decoration: underline;
            }
        }
    }
}