.app-kanban-wrapper {
    display: flex;
    flex-direction: column;
    .boards-wrapper-container {
        overflow-y: hidden !important;
        height: 100%;
        .ps__rail-x {
            z-index: 9999;
            position: absolute;
            .ps__thumb-x {
                border-radius: 0 !important;
            }
        }
    }
    .board-wrapper {
        border: solid 1px #dddddd98;
        max-width: 320px;
        overflow-y: auto;
        overflow-x: hidden;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        /* Works on Firefox */ 
        scrollbar-width: thin;
        scrollbar-color: rgba(89, 89, 89, 0.171) transparent;
        .kanban-column-container {
            flex: 1;
        }
        /* Works on Chrome, Edge, and Safari */
        &::-webkit-scrollbar {
            width: 7px;
        }
        &::-webkit-scrollbar-track {
            background: #e4e4e441;
        }
        &::-webkit-scrollbar-thumb {
            background-color: rgba(71, 71, 71, 0.171);
        }
        .title-board {
            position: sticky !important;
            top: 0;
            z-index: 1;
            border-radius: 0;
            border-bottom: solid 1px #cecece63;
            color: white;
            padding: 3.5px 8px;
            text-transform: uppercase;
            font-weight: 600;
            font-size: 10px;
            user-select: none;
            display: flex;
            align-items: center;
            justify-content: space-between;
            span {
                padding-top: 2px;
            }
        }
        .demands-list {
            padding: 6px;
        }
        &:not(:last-child) {
            margin-right: 0 !important;
        }
        .card {
            margin-top: 0 !important;
            margin-bottom: 5px !important;
            user-select: none;
            border-left: solid 3px;
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
            overflow: hidden;
            .priority-text {
                visibility: hidden;
                margin-top: -17px;
                transition: .15s;
                margin-bottom: -2px;
                display: flex;
                align-items: center;
                justify-content: space-between;
                font-size: 10.5px;
            }
            &:hover {
                .priority-text {
                    visibility: visible;
                    margin-top: 0px;
                }
            }
            hr {
                margin: 2px 0;
            }
            .card-body {
                cursor: pointer;
                padding: 8px 0.8rem 6px !important;
                &:hover {
                    background-color: #7b848f0c;
                }
                .copy-link-card {
                    padding-top: 1.5px;
                    padding-bottom: 1.5px;
                }
                .copy-link-card:hover {
                    color: #4f7faf;
                    text-decoration: underline;
                }
                .task-deal-name {
                    cursor: pointer;
                    color: #4f7faf;
                    font-weight: 600;
                    text-transform: uppercase;
                    font-size: 10px;
                    div {
                        margin-top: 3px;
                    }
                }
                .task-info span {
                    font-size: 10.5px;
                    b {
                        font-weight: 600 !important;
                    }
                }
            }
        }
        .tasks-total-value {
            position: sticky;
            bottom: 0;
            background-color: #fff;
            border: solid 1px;
            border-color: #77777738;
            box-shadow: 0px 0px 8px 3px #3636362f;
            text-align: center;
            padding: 6px;
            font-weight: 600;
            font-size: 13px;
            cursor: default !important;
        }
    }
    .kanban-filters {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        align-items: center;
        margin-bottom: 3px;
        margin-right: 5px;
        .filters-group {
            width: 100%;
            margin-bottom: 3px;
        }
        @media (max-width: 760px) {
            justify-content: center;
            .filters-group {
                justify-content: center !important;
                .input-search-demands {
                    width: 85%;
                    justify-content: center;
                    form, .input-group, input {
                        width: 100%;
                    }
                    input {
                        height: 2.3rem !important;
                        margin: 0.4rem 0 0.3rem;
                    }
                }
                .orders {
                    justify-content: center;
                }
            }
        }
        @media (max-width: 550px) {
            align-items: center;
            flex-direction: column;
            .filters-group {
                justify-content: center !important;
                .input-search-demands {
                    width: 100%;
                    input {
                        width: 100%;
                    }
                }
            }
        }

        .filters-group {
            flex-wrap: wrap;
            justify-content: space-between;
            .MuiButton-root {
                outline: none;
                white-space: nowrap;
            }
            .input-search-demands {
                .form-control {
                    height: 2rem;
                }
                form {
                    .input-group {
                        display: flex;
                        flex-wrap: nowrap;
                        align-items: center;
                    }
                    input {
                        border-top-right-radius: 0.357rem;
                        border-bottom-right-radius: 0.357rem;
                        min-width: 145px;
                    }
                    .btn {
                        padding: 4px 7px;
                        margin-top: 1px;
                    }
                }
            }
        }
    }
}

.deal-data {
    b {
        font-weight: 600;
        color: #737f91e3;
    }
    .input-radio-demand {
        flex-direction: row !important;
        margin: 6px 10px 0 10px;
        gap: 5px;
        span {
            padding: 0;
            margin-right: 2px;
        }
    }
    .demand-data {
        margin-left: 8px;
        margin-right: 8px;
    }
    .demand-data-broker {
        max-width: max-content;
        border-radius: 5px;
        margin: 4px 0;
    }
    .css-1e6y48t-MuiButtonBase-root-MuiButton-root {
        outline: 0;
        margin-top: -2px;
        svg {
            margin-top: -1px;
        }
    }
}
.repasse-integracao {
    .demand-data {
        margin: 0 !important;
        padding-bottom: 3px;
        &:first-child {
            padding-left: 1rem;
        }
    }
}
.process-modal {
    min-width: 40%;
    @media (max-width: 576px) {
        min-width: 100%;
    }
}
.editable-data {
    display: flex;
    align-items: center;
    gap: 4px;
    .isProducer {
        border-radius: 50%;
        background-color: #f2c923e0;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        font-weight: bold;
        width: 12px;
        height: 12px;
        padding: 2px 2px 0 2px;
        margin-left: 6px;
        font-size: 8px;
        color: #4a4a4a;
    }
    .hidden-svg {
        opacity: 0;
        transition: .1s all;
    }
    svg {
        margin-top: 1px;
    }
    &:hover {
        cursor: text;
        .hidden-svg {
            cursor: pointer;
            opacity: 1;
        }
    }
}
.process-data-section {
    &:not(:last-child) {
        margin-bottom: 1.3rem;
    }
    &:last-child {
        margin-bottom: 8px;
    }
    h2 {
        cursor: pointer;
        font-size: 15px;
        font-weight: bold;
        display: flex;
        align-items: center;
        max-width: max-content;
        margin: 0;
        span, svg:first-child {
            color: #548ac0;
        }
        svg:first-child {
            margin: -1px 5px 0 0;
        }
        svg:last-child {
            margin-left: 5px;
        }
    }
    .new-process-form-group {
        margin-left: 1rem;
        margin-right: 12px;
    }
    .form-groups-container {
        border-left: solid 2px #4468ac1f;
        margin-left: 3px;
        margin-top: 6px;
    }
    .demand-data {
        .MuiInputBase-root {
            width: 100%;
            input, select, textarea {
                padding-top: 2px !important;
            }
            &::before {
                border-color: #3d77b1;
            }
        }
    }
}
.filter-date {
    label {
        width: max-content;
        .dropdown-toggle {
            padding: 5px 12px 5px 5px !important;
            font-size: 12px;
            display: flex;
            align-items: center;
            &::after {
                margin-left: -7px;
            }
        }
    }
}
.new-client-data {
    font-size: 13px;
    margin-bottom: .75rem;
}
.personalized-select-option {
    color: #2b2b2b;
}

.dark-layout {
    .kanban-application {
        .board-wrapper {
            border: solid 1px #dddddd17;
            .tasks-total-value {
                background-color: #283046;
            }
            ul.pagination {
                background-color: #283046 !important;
                color: #ebebeb;
            }
        }
        .board-wrapper {
            &::-webkit-scrollbar-track {
                background: transparent !important;
            }
        }
    }
    .demand-data .MuiInputBase-root {
        input, select, textarea {
            color: #bbbbbb !important;
        }
    }
    .label-add-process {
        color: #999999 !important;
    }
    .modal-add-process {
        .rodape-add-process {
            background-color: #283046;
        }
    }
}
