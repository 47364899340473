.comment-container,
.obs-container,
.timeline-container {
    .myTimeline {
        div {
            display: flex;
            flex-direction: column;
            gap: 0;
        }
    }

    .observacoes span p {
        font-weight: normal !important;
    }

    .obs-content {
        white-space: pre-wrap;
        background-color: transparent !important;
        padding: 0;
        margin: 0;
        overflow-wrap: break-word;
        color: #272727;
        font-weight: normal;
    }


    .card-body {
        padding: 0 0 !important;
        margin-left: 1rem;
    }

    .timeline {
        margin-left: 0;
        border-left: solid 1px #80808024;

        li.timeline-item {
            padding: 0 0 0 1.5rem !important;
            cursor: default !important;

            &:hover {
                box-shadow: none;
            }

            .timeline-event {
                p {
                    span:nth-child(2n) {
                        font-weight: normal;
                        font-size: 13px;
                    }
                }
            }

            .timeline-event>div {
                display: none !important;
            }
        }

        li.timeline-item>span {
            margin-top: 5px;
            width: 8px !important;
            height: 8px !important;

            &::before {
                width: 14px !important;
                height: 14px !important;
                margin-top: 1px;
                margin-left: 1px;
            }
        }
    }
}
.timeline .timeline-item {
    .timeline-point {
        z-index: 0 !important;
    }
    &::after {
        z-index: -1 !important;
    }
}

.dark-layout {
    .obs-content {
        color: #d3d3d3;
    }
}