.commissions-app-list {
    .p-datatable, .receipt-table-container {
        height: 89%;
    }
    .p-datatable-wrapper {
        height: 100%;
    }
    .prepend {
        height: 2rem;
        .input-group-text {
            padding: 6px;
        }
    }
    .input-policy-number {
        display: flex;
        align-items: center;
        input {
            max-width: 150px;
            height: 2rem;
            font-size: 12px;    
        }
    }
    .flat-btn {
        padding: 2px 4px;
    }
}

.dark-layout {
    .p-datatable-table {
        thead th {
            background-color: rgb(73, 79, 97) !important;
            color: #ffffffb9 !important;
        }
        tbody {
            tr {
                background-color: #303749 !important;
                color: #ffffffb2;
            }
        }
    }
    .p-paginator {
        background-color: #283046 !important;
    }
    .input-policy-number {
        input {
            border-left: none !important;
            border-color: #ffffff17 !important;
        }
    }
    .prepend {
        .input-group-text {
            border-color: #ffffff17 !important;
            padding: 6px;
        }
    }
    .p-dropdown {
        background: #E3F2FD !important;
    }
}
