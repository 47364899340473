.modal-add-process {
    position: relative;
    .modal-body {
        margin-top: 1.5rem !important;
        .form-group {
            display: flex;
            flex-direction: column;
        }
    }
    .collapse {
        #add-broker-to-process {
            margin-left: 1rem;
            margin-bottom: 7px;
        }
        button {
            max-height: 2.8rem;
        }
    }
    .rodape-add-process {
        position: sticky;
        bottom: 0;
        width: 100%;
        background-color: #fff;
        button {
            padding: 8px 10px;
            font-size: 13px;
        }
    }
}
.header-new-process {
    h5 {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
}
