.dashboard-container {
    overflow-y: scroll;
    overflow-x: hidden;
    padding: 1rem;
    /* Works on Firefox */
    scrollbar-width: thin;
    scrollbar-color: rgba(71, 71, 71, 0.171) transparent;
    /* Works on Chrome, Edge, and Safari */
    &::-webkit-scrollbar {
        width: 8px;
    }
    &::-webkit-scrollbar-track {
        background: transparent;
    }
    &::-webkit-scrollbar-thumb {
        background-color: rgba(71, 71, 71, 0.171);
        border-radius: 20px;
    }
    .filter-dashboard-data {
        justify-content: space-between;
        align-items: center;
    }
    .card-list {
        .card-header {
            flex-direction: column;
            align-items: flex-start;
        }
        .status-item {
            display: flex;
            justify-content: space-between;
            align-items: center;
            gap: 10px;
            .avatar-content {
                width: 22px;
                height: 22px;
            }
        }
    }
}

.btn-active {
    background-color: #1e72c7dc;
    color: #fff !important;
    &:hover {
        background-color: #1e72c7dc;
    }
}

.filter-dashboard-data {
    margin-left: -5px;
    margin-right: 0px;
    .tab-dashboard-sections {
        margin-left: 12px;
        margin-right: 10px;
        button {
            border: 1px solid #1e72c780;
            color: #1e73c7;
            outline: none;
        }
        button:first-child {
            border-top-left-radius: 5px;
            border-bottom-left-radius: 5px;
        }
        button:last-child {
            border-top-right-radius: 5px;
            border-bottom-right-radius: 5px;
        }
    }
    .date-filter-dashboard {
        align-items: center;
        gap: 5px;
        .form-control {
            min-width: 210px;
            background-color: #fff !important;
            box-shadow: 0 4px 24px 0 rgba(34, 41, 47, 0.089);
            opacity: .9 !important;
            border: solid 1px #cfcfcf73 !important;
            &::placeholder {
                color: rgb(177, 177, 177);
            }
        }
        .clear-date-value {
            padding: 3px;
            svg {
                cursor: pointer;
                &:hover {
                    color: #ea5455;
                }
            }
        }
    }
    @media (max-width: 520px) {
        gap: 10px;
        flex-direction: column;
        align-items: center;
        .tab-dashboard-sections {
            margin-bottom: 10px;
        }
        .date-filter-dashboard {
            width: 100%;
            margin-left: 8px;
        }
    }
}
.card-table {
    table {
        .th-leads, .td-leads {
            text-align: center;
        }
    }
    .rank-container {
        font-size: 14px;
        font-weight: 600;
        margin-right: 10px;
    }
    .client-name {
        font-weight: 500;
        font-size: 13px;
    }
}
.custom-avatar .avatar-content {
    width: 24px !important;
    height: 24px !important;
    svg {
        max-width: 15px;
        height: auto;
    }
}
.process-by-broker {
    box-shadow: 0 4px 24px 0 rgba(34, 41, 47, 0.1);
    .title-process-by-broker {
        display: flex;
        align-items: center;
        padding: 10px;
        margin-bottom: 0;
        h4 {
            font-size: 1.285rem;
            font-weight: 500;
            margin: 0;
        }
    }
    .apexChart-process-by-broker {
        padding-top: 1.5rem;
        padding-bottom: 1rem;
        background-color: #fff;
    }
    .table-stage-by-broker {
        margin-bottom: 0;
        thead {
            border-top: solid 2px #e5e5e5c0;
            tr th {
                padding: 4px;
                text-align: center;
                &:first-child {
                    white-space: nowrap;
                    border-right: solid 1px #e5e5e5;
                }
            }
        }
        .td-name-broker {
            border-right: solid 1px #e5e5e5;
            font-weight: 500;
        }
        .td-stage-processes {
            padding: 5px;
            .row {
                margin: 0 10px;
                div {
                    font-size: 13px;
                    font-weight: 500;
                    margin: 0 6px;
                    &::after {
                        content: ',';
                    }
                    &:last-child {
                        &::after {
                            content: '' !important;
                        }
                    }
                    span:last-child {
                        color: #bb6749;
                    }
                }
    
            }
        }
    }
}

// top 10 premio total
.sc-fzoiQi {
    width: 100%;
    text-align: center;
    margin: 1rem 0 0;
    padding-bottom: 1rem;
    border-bottom: solid 1px #e5e5e5;
}
.sc-fzozJi {
    background-color: #f3f2f7;
    min-height: 2.5rem !important;
    .sc-fzoLsD:not(:first-child) {
        border-right: solid 1px #e5e5e5;
    }
}
.sc-fzoLsD {
    border-right: solid 1px #e5e5e5;
}
.expandable-content {
    border: solid 1px #e5e5e5;
}
.expandable-content div {
    margin-left: .2rem;
    b {
        font-weight: 600;
        color: #5e5e5e;    
    }
    div > div {
        white-space: nowrap;
        margin-top: 2px;
        svg {
            margin-top: -2px;
            margin-right: 1px;
            color: #5e5e5e;
        }
    }
}

.dark-layout {
    .apexChart-process-by-broker {
        border: solid 2px #323c50a2;
        background-color: transparent;
    }

    // top 10 premio total
    .sc-fznWqX, .sc-fzoiQi, .sc-Axmtr div {
        background-color: #283046 !important;
        color: #e5e5e5d0 !important;
        border-color: rgba(104, 104, 104, 0.425) !important;
    }
    .bvRSZL {
        border-right: none !important;
    } 
    .expandable-content div b {
        color: rgba(156, 160, 167, 0.911);
    }
    .sc-fznKkj svg {
        color: #e5e5e556 !important;
    }
}