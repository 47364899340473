//Breakpoints
@mixin for-large-mobile {
    @media (min-width: 501px) and (max-width: 700px) {
        @content;
    }
}
@mixin for-small-mobile {
    @media (max-width: 500px) {
        @content;
    }
}
@mixin for-tiny-mobile {
    @media (max-width: 400px) {
        @content;
    }
}