.formControl-tags {
    outline: solid 1px #c1c1c1b2;
    color: white !important;
    border-radius: 6px;
    margin: 8px 1.5rem 8px 1.5rem;
}
.MuiInputLabel-outlined {
    margin: -9px 0 0 -6px;
}
#demo-multiple-checkbox {
    padding: 0 !important;
    height: 100%;
    align-items: center;
    display: flex;
    margin: 0 2em 0 10px;
    text-transform: lowercase;
}
.MuiPaper-rounded {
    text-transform: lowercase;
}
.MuiInputBase-formControl {
    height: 100% !important;
}
.MuiInputLabel-formControl {
    transform-origin: top right !important;
    z-index: 0 !important;
}
.css-1sumxir-MuiFormLabel-root-MuiInputLabel-root.Mui-focused {
    right: -10%;
    top: -3px;
}
.MuiSelect-icon {
    color: #5e5873 !important;
}
.MuiPopover-paper {
    margin-bottom: 0;
}
.MuiMenuItem-gutters {
    padding: 0 0 0 5px;
}

.dark-layout {
    .formControl-tags {
        outline: solid 1px $blue-primary
    }
    .MuiPaper-rounded {
        background-color: #3a425f;
        color: rgba(193, 193, 193, 0.979);

        .MuiSvgIcon-root {
            color: rgba(193, 193, 193, 0.979);
        }
    }
    #demo-multiple-checkbox {
        color: rgba(193, 193, 193, 0.979);
    }
}

