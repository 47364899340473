.form-comercial-card {
    padding: 0 1.5rem;
    .card-header {
        padding: 1.5rem 0.6rem 1.2rem !important;
        h4 {
            margin: 0;
        }
        border-bottom: solid 1px #8584843e;
    }

    .card-body {
        padding: 0;

        .form-groups-container {
            margin-left: 0.5rem !important;
            margin-top: 0.5rem !important;
            padding-left: 0.75rem !important;
        }
        .form-group {
            display: flex;
            flex-direction: column;
        }
        textarea {
            padding: 10px !important;
        }
    }
}