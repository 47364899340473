.sidebar-kanban-modal {
    .tags-collapse {
        .select-tag-container {
            padding: 0 1rem;
            margin-top: -0.4rem;
        }
        .badge-tag {
            margin: 3px 5px !important
        }
        .titulo-campo-tag {
            font-size: 11px;
            text-transform: uppercase;
            font-weight: bold;
            opacity: 0.8;
            margin-bottom: -8px;
            margin-left: 3px;
        }
    }
    .quoting-collapse {
        .insurance-data {
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 100%;
            .insuranceInfo {
                display: flex;
                align-items: center;
                gap: 0.50rem;
               .badge {
                    gap: 0.25rem;
                    background-color: #9b9b9b36;
                    color: inherit;
                    padding: 3px 7px;
                    font-size: 10px;
                    svg {
                        margin-right: 2.5px;
                        width: 10px;
                        height: auto;
                    }
                }
            }
        }
        ul.insurance-list {
            li {
                list-style: none !important;
                border: solid 1px #2830462b;
                border-radius: 8px;
                padding: 4px 10px;
                margin-bottom: 8px;
                background-color: #b6b5b50e;
                display: flex;
                align-items: center;
                justify-content: space-between;
                gap: 6px;
                flex-wrap: wrap;
                .quotation-timer {
                    margin-top: 2px;
                }
                .quotation-value {
                    margin-top: 2px;
                }
            }
        }
        .css-5ryogn-MuiButtonBase-root-MuiSwitch-switchBase.Mui-checked {
            color: #ffffffec !important;
        }
        @media (max-width: 450px) {
            li {
                flex-direction: column;
                align-items: flex-start !important;
            }
            .MuiFormControlLabel-label {
                span br {
                    display: none !important;
                }
            }
            .timer-action {
                margin-top: -6px;
            }
        }
    }
    .kanban-demand-modal {
        overflow: hidden;
        height: 100%;
        position: relative;
        .process-modal-float-buttons {
            position: absolute;
            bottom: 1.2rem;
            right: 1.5rem;
            padding: 9px;
            transition: .2s;
        }
        .modal-header {
            opacity: .8;
            border-bottom: solid 2px #adadad63;
            background: linear-gradient(118deg, #1e73c7, rgba(30, 115, 199, 0.7)) !important;
            .modal-title {
                color: #fff;
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: space-between;
                font-size: 16px;
                text-transform: uppercase;
                font-weight: bold;
            }
        }
        .modal-body {
            overflow-y: scroll;
            position: relative;
            padding-left: 0;
            padding-right: 0;
            .form-group {
                margin-bottom: 0;
            }
            h1 {
                padding: 0 1.2rem;
                font-size: 15px;
                text-transform: uppercase;
            }
            .info-main {
                padding: 0 1.2rem;
            }
            .collapsed-data {
                border-bottom: solid 1px;
                border-color: #adadad63;
                padding: 1rem 1.2rem .6rem;
                margin: 0;
                width: 100%;
                font-size: 13px;
                &:hover h3 {
                    color: #4a8cce;
                    cursor: pointer;
                }
                h3 {
                    font-size: 15px;
                    font-weight: bold;
                    &:hover {
                        color: #548ac0 !important;
                    }
                    svg {
                        margin-right: 3px;
                    }
                }
                .about-deal .data-list .process-data-section {
                    .form-groups-container .demand-data {
                        margin-left: 8px;
                        margin-right: 8px;
                        padding-bottom: .5rem !important;
                    }
                    .demand-data {
                        margin-left: 1rem !important;
                        margin-right: 1rem;
                        padding-bottom: .5rem !important;
                    }
                }
                .repasse-integracao .demand-data {
                    margin: 0 !important;
                    &:first-child {
                        padding-left: .8rem;
                    }
                }
                .address-group .demand-data {
                    margin-top: -12px;
                    &:last-child {
                        padding-top: .6rem;
                    }
                }
                .date-group .demand-data {
                    margin-top: 3px;
                    margin-bottom: 3px;
                    &:last-child {
                        border-left: solid 1px #7474743b;
                        padding-left: 1rem;
                    }
                }
            }
        }
    }
}
.jodit-placeholder {
    position: relative;
    z-index: 0 !important;
}
.jodit-wysiwyg {
    cursor: text !important;
}
.jodit-status-bar-link {
    display: none;
}
.obs-process {
    .process-obs-text {
        display: flex;
        flex-wrap: wrap;   
    }
    .timeline-event {
        min-height: auto !important;
        padding-bottom: 6px;
    }
}
.inner-html p {
    margin: 0;
    max-width: max-content !important;
}
.timeline .comentario > p {
    display: flex;
    align-items: center;
    gap: 0.25rem;
    flex-wrap: wrap;
}
.activity-container,
.process-files {
    .nav {
        margin-left: .8rem;
        font-size: 12px;        
        .nav-item .nav-link {
            padding: 3px 6px;
        }
    }
    .process-log {
        margin: 0 1rem .5rem;
        .comentario p {
            align-items: flex-start !important;
        }
    }
    .p-paginator {
        flex-direction: row !important;
        background: transparent;
        button {
            min-width: 20px !important;
            height: 20px !important;
        }
    }
}

.files-timeline {
    .file-data {
        .label-for-information {
            display: inline-flex;
            align-items: center;
            flex-wrap: wrap;
            gap: 6px;
            margin-left: -6px;
            b {
                display: inline-flex;
                align-items: center;
                gap: 3px;
                &:last-child svg {
                    margin-right: -1px;
                }
            }
        }
    }
}

.fatherBroker {
    margin-left: 0 !important;
    font-weight: 500;
    opacity: 0.9;
    &:not(:first-child) {
        margin-top: 0.50rem !important;
    }
}
.childBroker {
    font-size: 13px;
}

.modal-process-quotation-insurance {
    // animação relogio girando no modal de status (processQuotationInsurance)
    .hourglass-animation {
        animation: rotate-animation 2.5s infinite;
        @keyframes rotate-animation {
            from {
                transform: rotate(0deg);
            }
            to {
                transform: rotate(360deg);
            }
        }
    }

    .process-quotation-insurance-obs {
        .obs-title {
            display: flex;
            align-items: center;
        }
        .badge {
            background-color: #9b9b9b36;
            color: inherit;
            padding: 3px 5px;
            font-size: 10px;
            margin-left: 6px;
            margin: 0;
            display: flex;
            align-items: center;
            justify-content: center;
        }
        .timeline:not(:first-child) {
            margin-top: -5px;
        }
    }
}