.scrollbar {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}

.colorStatus {
    width: 8px;
    height: 8px;
    border-radius: 50%;
}

.list-leads {
    width: 100%;
    padding: 0;
    margin-bottom: 4.5rem;
    border-bottom: solid 3px rgba(187, 187, 187, 0.14);
    border-top: solid 1px rgba(187, 187, 187, 0.14);

    .lead-card {
        display: flex;
        justify-content: space-between;
        padding: 0 1rem;
        box-shadow: 0px -2px 3px 1px  rgba(196, 196, 196, 0.14);
        cursor: pointer;
        padding: 8px 1.5rem 8px;
        transition: all .08s;
        &:hover {
            border-left: solid 2px $blue-primary;
        }

        .icon-title-lead{
            font-size: 20px;
        }

        .titleLead {
            width: 100% !important;
            display: flex;
            justify-content: space-between;
            align-items: center;

            @include for-small-mobile {
                flex-direction: column;
            }

            div {
                @include for-small-mobile {
                    @include flexCenter;
                    flex-direction: column;
                    text-align: center;
                }
                span {
                    display: flex;
                    align-items: center;
                    justify-content: flex-end;
                    gap: 8px;

                    h1 {
                        font-size: 15px;
                        @include for-small-mobile {
                            font-weight: 550;
                        }
                    }
                    h2 {
                        font-size: 12px;
                    }
                    h3 {
                        font-size: 13px;
                    }

                    .icon-title-lead {
                        font-size: 14px;
                        margin-bottom: 8px;
                    }
                }
                .statusTitle {
                    font-size: 13px;
                    @include for-large-mobile {font-size: 12px;}
                    @include for-small-mobile {font-size: 12px;}
                }
            }

            .namesTitle {
                span {
                    justify-content: flex-start !important;
                }
            }

            .group-date-status {
                @include for-small-mobile {
                    display: flex;
                    flex-direction: row;
                    justify-content: center;
                    align-items: center;

                    #date-title {
                        svg {margin: 0}
                        h3 {margin: 0}
                    }
                    .statusTitle {
                        border-left: solid 1px rgba(24, 24, 24, 0.112);
                        padding-left: .8rem;
                        margin-left: .8rem;
                    }
                }
            }
        }
    }
}

.dark-layout {
    .list-leads {
        .lead-card {
            box-shadow: 0px -1px 1px 1px  rgba(109, 109, 109, 0.14) !important;
        }

    }
}