.financial-media-list {
    width: 100%;
    margin: 0 0 4rem 0;
    padding: 0;
}
.filtered-data {
    align-items: center;
    display: flex;

}
.financial-card-container {
    border: solid 1px #8383832f;
    box-shadow: 0 0px 8px 1px rgba(#505050, 0.1);
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 5px;
    border-top-right-radius: 5px;
    margin: 1rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    .financial-data {
        position: relative;
        cursor: default;
        width: 100%;
        font-size: 11px;
        font-weight: 500;
        &:hover {
            .triangle {
                border-bottom-color: #ebaa49;
            }
        } 
        .processName {
            margin: 3px 4px -2px;
            margin-left: .9rem;
            font-weight: bold;
            text-transform: uppercase;
            max-width: max-content;
            color: #5275b8;
        }
        .card-data {
            margin: 4px 10px 10px 1rem;
            margin-top: 0.40rem;
            gap: 1rem;
            .col {
                padding: 0;            
            }
            span {
                font-weight: bold;
            }
            @media (max-width: 998px) {
                display: grid;
                grid-template-columns: repeat(2, 1fr);
                grid-gap: 0rem !important;
            }
            @media (max-width: 700px) {
                display: grid;
                grid-template-columns: 1fr;
            }   
        }
    }
    .btn-edit-installment {
        padding: 4px;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 4px;
        width: 85%;
    }
    @media (max-width: 500px) {
        flex-direction: column;
        align-items: flex-start;
        .payment-data-options {
            display: flex;
            align-items: center;
            margin: -4px 1.5rem 5px;
        }

        .btn-edit-installment {
            padding: 6px;
            margin-top: -4px;
        }
    }
    .card-options-btn {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-left: 1rem;
    }        
    .checkbox-paid {
        cursor: pointer;
        width: 15px;
        height: 15px;
        position: absolute;
        right: 10px;
        top: 10px;
    }

}
.borderAround {
    border: solid 2px #5386e431;
    &:hover {
        border-color: #5276b8c4;
    }
}
.financial-options {
    .filters-and-actions {
        width: 100%;
        justify-content: space-between;
        padding: 0 1rem;
        margin-bottom: -10px;
        margin-top: 5px;
        button {
            outline: 0 !important;
        }
    }    
    strong {
        font-size: 10px;
        color: #fa3232;
        font-weight: 500;
        display: block;
        margin: 7px 1rem -2px;
        text-align: end;
    }
}
.multiples-btn {
    margin: -1.5px 4px 0 0;
}
.value-action-btn {
    padding: 6px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #ea18181c;
}
.payment-value {
    display: flex;
    align-items: center;
    gap: 6px;
    .btn-edit-value {
        padding: 3px;
        display: flex;
        align-items: center;
        justify-content: center;
    }       
}
.import-component .card {
    margin: 7px .5rem 1rem 1rem;
    .card-body {
        display: flex;
        align-items: center;
        justify-content: center;
        border: dashed 2px $blue-primary;
        .attached-file {
            padding: 1rem;
            font-weight: 500;
            svg {
                color: $blue-primary;
            }
        }
        .custom-label {
            text-align: center;
            width: 100%;
            height: 100%;
            padding: 2rem;
            p span {
                color: $blue-primary;
                font-weight: 600;
                cursor: pointer;
                &:hover {
                    text-decoration: underline;
                }
            }
            svg {
                color: $blue-primary;
            }
        }
    }
}
.upload-file-response {
    .card-data {
        background-color: rgba(131, 131, 131, 0.144);
        padding: 5px 8px;
        border-radius: 5px;
        width: 100%;
        display: inline-flex;
        justify-content: space-between;
        align-items: center;
        font-weight: 500;
        &:not(:last-child) {
            margin-bottom: 8px;
        }
        svg {
            color: $blue-primary;
        }
    }
}
.modal-file-data {
    .modal-card-data {
        background-color: rgba(131, 131, 131, 0.096);
        padding: 1rem;
        border-radius: 5px;
        width: 100%;
        border: solid 1px rgba(119, 119, 119, 0.227);
        font-size: 12px;
        &:not(:last-child) {
            margin-bottom: 10px;
        }
    }
}
.payment-tax-container {
    margin-left: -0.25rem;
    padding-left: 0.85rem;
    border-left: solid 2px #49566f30;
    .aviso strong:last-child {
        opacity: 0.9;
    }
}

.dark-layout {
    .processName {
        color: #678fda !important;
    }
    .css-j204z7-MuiFormControlLabel-root .MuiFormControlLabel-label.Mui-disabled {
        color: #cfcfcfcb !important;
    }
}