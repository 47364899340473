
.loading-data {
    width: 100%;
    &>div {
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 8px;
        font-weight: 500;
        margin: 4rem;
        .spinner-border {
            color: #1e73c7;
            width: 20px;
            height: 20px;
            min-width: 20px;
            min-height: 20px;
        }
    }
}
.applied-filters {
    .legenda-isClient {
        padding: 0 8px;
        margin: 0 5px;
        flex-wrap: nowrap;
        border-radius: 3px;
        border: solid 1px #9696962c;
        max-height: 25px;
        max-width: max-content;
        span {
            white-space: nowrap;
        }
        span:first-child {
            border-right: solid 1px #9696962c;
            margin-right: 8px;
            padding-right: 8px;
        }
    }
    @media (max-width: 650px) {
        flex-direction: column;
        .legenda-isClient {
            margin: 10px 0 5px 0;
        }
    }
}
.applying-filters {
    padding: 5px;
    font-weight: 500;
    svg {
        max-width: 13px;
        height: auto;
        margin: 0 3px 1.5px 0;
    }
    &::after {
        content: '...';
        animation: loading 1s infinite steps(3);
    }
    @keyframes loading {
        0% {
            content: '.';
        }
        33% {
        content: '..';
        }
        66% {
        content: '...';
        }
    }
}

// ** Alerta confirmar alteração de status
.swal2-show {
    @media (max-width: 600px) {
        max-width: 70% !important;

        .swal2-header {
            padding: 0 !important;
            .swal2-icon-show {
                margin: 1rem !important;
                max-width: 45px !important;
                max-height: 45px !important;
                .swal2-x-mark-line-left {
                    left: 6px !important;
                    top: 1.45em;
                    width: 2.3em;
                }
                .swal2-x-mark-line-right {
                    right: 6px;
                    top: 1.45em;
                    width: 2.3em;
                }
            }
            .swal2-title {
                font-size: 17px !important;
                padding: 5px;
            }
        }
        .swal2-content {
            padding: 0px !important;
            .swal2-html-container {
                font-size: 14px !important;
            }
        }
        .swal2-actions {
            gap: 10px;
            button {
                padding: 8px !important;
                font-size: 14px !important;
            }
            button:nth-child(2n) {
                margin-left: 0 !important;
            }
        }
    }
}

.input-search-lead {
    .btn-searchLead {
        padding: 0;
        margin-right: 8px;
        min-width: 1.5rem;
        width: 4rem;
        height: 80%;
        @include flexCenter;
        @include for-small-mobile {
            width: auto;
            height: 70%;
        }
    }
}

// menu status
.MuiPaper-elevation {
    padding: 0 !important;
    &::-webkit-scrollbar {
        width: 12px;
      }

    &::-webkit-scrollbar-track {
        background: rgb(229, 229, 229);
    }

    &::-webkit-scrollbar-thumb {
        background-color: $blue-primary;
        border-radius: 20px;
        border: 3px solid rgb(229, 229, 229);
    }
}
.menu-item-status {
    display: flex;
    width: 100%;
    margin: 0 !important;
    padding: 5px 1.5rem !important;
    gap: 10px;
    align-items: center;
    font-weight: 500;
    width: 100%;
    text-transform: capitalize;
}
#select-status {
    background: linear-gradient(90deg, rgba(228,228,228,1) 0%, rgba(116,167,221,1) 35%, rgba(45, 94, 147, 0.859) 100%) !important;
    height: 30px !important;
    color: white;
    border-radius: 5px;
    outline: none;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    margin: 7px 0;
    gap: 8px;
    padding: 0 1.2rem;
}
#lastStatus {
    @include for-tiny-mobile {font-size: 11px;}
}

// ** Lead container
.lead-container {
    margin: 6px 1.2rem 7rem;

    .lead-item {
        cursor: default;
    }

    .card-body {
        padding-top: 0 !important;
    }

    .card-header {
        display: flex;
        justify-content: center;
        padding: 1.7em 0 1rem;

        .upper-title {
            .lead-title-container {
                @include flexCenter;
                text-align: center;
                gap: 7px;
                height: auto;
                padding: 5px;
            }

            // ** nome da construtora
            #care-icon {
                font-size: 1em;
                //responsive
                @include for-tiny-mobile {display: none;}
                @include for-small-mobile {font-size: 14px;}
            }
            .enterprise-name {
                font-size: 1.3em;
                color: #4080c1;
                margin: 0;
                //responsive
                @include for-tiny-mobile {font-size: 15px;}
                @include for-small-mobile {font-size: 17px;}
            }

            // ** nome da corretora
            #inspection-icon {
                font-size: .9em;
                //responsive
                @include for-tiny-mobile {display: none;}
                @include for-small-mobile {font-size: 14px;}

            }
            .constructionCompany-name {
                font-size: 1em;
                margin: 0;

                //responsive
                @include for-tiny-mobile {font-size: 13px;}
                @include for-small-mobile {font-size: 14px;}

            }
        }
    }

    .information {
        padding: 8px 8px 6px;

        .label-for-information {
            @include flexLabel;

            #city-icon {
                color: rgb(177, 57, 57);
            }
        }

        span {
            font-size: 12px;
            font-weight: bold;
        }

        h2 {
            margin: 0;
            padding-top: 3px;
            font-size: 16px;
            font-weight: normal;

            // ** responsive
            @include for-tiny-mobile {font-size: 14px;}
            @include for-small-mobile {font-size: 15px;}
        }
    }

    .client-container {
        display: flex;
        justify-content: space-between;
        flex-direction: column;
    }

    .clients, .corretoras {
        padding: 4px 0;
    }

    .see-more span {
        transition: .2s;
        gap: 5px;
        font-size: 11px;
        padding: 4px;
        font-weight: 500;
    }

    .clients {
        span {
            cursor: pointer;
        }
        .list-group-item {
            gap: 10px;
            .client-options span, .see-more {
                &:hover {
                    color: $blue-primary;
                }
            }
            ul li {
                &:hover {
                    transform: translateY(-1px) !important;
                }
            }   
            .client-name {
                span {
                    font-size: 13px;
                    font-weight: 500;
                }
                .is-true {
                    margin-right: 4px;
                }
            } 
            .client-options {
                margin-top: 5px;
                display: grid;
                grid-template-columns: repeat(2, 1fr);
                column-gap: 8px;    
                row-gap: 2px;
                @media (max-width: 450px) {
                    grid-template-columns: 1fr;
                    column-gap: 0;
                }
                span {
                    gap: 5px;
                    font-size: 10px;
                }

                @media (max-width: 325px) {
                    flex-direction: column;
                    gap: 5px;
                }
            }
            @media (max-width: 550px)  {
                flex-direction: column;
                align-items: flex-start !important;
                .see-more {
                    border: solid 1px #00000023;
                    border-radius: 5px;
                    font-size: 10px;
                }
            }    
        }
    }

    .container-client-name {
        display: flex;
        justify-content: space-between;
    }

    .item-name > span:hover {
        color: $blue-primary;
    }
    .item-name {
        display: flex;
        justify-content: space-between;
        width: 100%;

        &:hover svg {
            color: $blue-primary;
        }

        span {
            cursor: pointer;
        }

        .list-group-item {
            padding: 8px !important;
            background: #fdfdfd9d;
            border-radius: 5px;
            cursor: default;

            h2 {
                margin: 0;
                padding-left: 3px;
                font-size: 14px;
            }
        }
    }

    .list-group-item {
        @media (max-width: 700px) {
            gap: 1rem;
        }
    }

    .broker-options {
        display: flex;
        align-items: center;
        gap: 8px;
        @media (max-width: 350px) {
            span {
                display: none !important;
            }
        }
    }

    .users-details-options {
        display: flex;
        align-items: center;
        gap: 1rem;

        span {
            gap: 5px;
            font-size: 10px;
        }

        @media (max-width: 700px) {
            flex-direction: column;
            gap: 8px;
            span:nth-child(2n) {
                flex-direction: row-reverse;
            }
        }
    }

    .corretoras, .users-container {
        &:hover button.btn-delete {
            svg {
                color: #fff;
            }
        }
        button.btn-delete {
            transition: .2s;
            padding: 3px;
            svg {
                max-width: 15px;
                height: auto;
            }    
            &:hover {
                transform: translateY(-2px);
            }
        }
    }

    .client-container, .brokers-container, .users-container {
        .list-group-item:hover {
            box-shadow: none !important;
        }
    }

    .brokers-container {
        .nav-tabs {
            margin-bottom: 4px;
        }
    }

    // ** observações
    .newObs {
        gap: 5px;
    }

    .label-comment {
        margin-bottom: 10px;
    }

    .newComment-container {
        margin-top: 1rem;

        #plus-icon {
            margin-left: 5px;
        }

        button {
            padding: 7px;
            font-size: 12px;
        }

        .addComment {
            margin-top: 7px;
            display: flex;
            flex-direction: column;

            #comment-area {
                border: solid 2px #36363640;
                background-color: #ffffff;
                resize: none;
                width: 100%;
                padding: 5px;
            }

            button {
                align-self: flex-end;
                width: 10rem;
                margin-top: 10px;
                border-radius: 5px;
                border: none;
                background-color: $blue-primary;
                color: white;
                font-weight: 600;
                padding: 5px;
            }
        }

        #toggler {
            @include for-small-mobile {font-size: 13px; padding: 10px;}
            @include for-tiny-mobile {font-size: 12px; padding: 8px;}
        }
    }
}

.dark-layout {
    .input-search-lead input {
        border: none !important;
    }
    .card-header {
        &:hover {
            background: #a3a3a316 !important;
        }
        .card-title .titleLead div span {
            color: #dbdbdbc2 !important;
            h1, h2, h3 {
                color: #dbdbdbc2 !important;
            }
        }
    }
    .input-select-tags > div {
        border: solid 1px #dbdbdb21 !important;
    }
    .input-select-tags {
        background-color: transparent !important;

        div div div div div:nth-child(1) {
            color: #1e73c7 !important;
        }
        #react-select-16-listbox {
            background-color: #3a425f !important;
            color: #c2c2c2;
        }
        div {
            background-color: transparent !important;
        }
    }
    .item-name {
        .list-group-item {
            background: #8f8f8f;
        }
    }
    .lead-container {
        .constructionCompany-name {
            color: #ffffffa2 !important;
        }
        .information {
            background-color: transparent;
            border: solid 2px #4081c194;
            color: #8e9fcf;

            h2 {
            opacity: .9;
            }

            #select-status {
                background: linear-gradient(90deg, rgba(112,125,162,1) 0%, rgba(74,83,111,1) 100%) !important;
            }
        }
        .comment-container {
            .comentario {
                color: #ffffffc2;
            }
            .newComment-container {
                button {
                    opacity: .9;
                }
                textarea {
                    background: transparent !important;
                    border: solid 2px #ffffff91 !important;
                    border-radius: 5px;
                    color: #ffffffb7;
                }
            }
        }
    }
    // input-add-broker
    .css-13cymwt-control {
        background-color: transparent !important;
        border: solid 1px #85858544;
    }
    .css-t3ipsp-control {
        background-color: transparent !important;   
    }
    .css-1dimb5e-singleValue {
        color: #c2c2c2 !important;
    }
    .css-1nmdiq5-menu {
        background-color: rgb(22, 29, 49) !important;
    }
}